.centerIcon {
  vertical-align: sub;
}

.confettis {
  margin: 0 auto;
}

.loggedInDetails {
  text-align: center;
}

.spinnerCell {
  display: flex;
  justify-content: center;
}